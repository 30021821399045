import React from 'react';

export default function Navbar() {
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-blue-500 mb-3 text-white font-header">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-2xl py-2 whitespace-nowrap tracking-widest"
              href="/"
            >
              Bad Boys Book Bangers
            </a>
          </div>
          <div className="flex justify-between gap-x-12 text-xl text-white tracking-wider">
            <a
              className="hover:underline"
              href="/boys"
            >
              The Boys
            </a>
            <a
              className="hover:underline"
              href="/"
            >
              The Books
            </a>
            <a
              className="hover:underline"
              href="/bangers"
            >
              The Bangers
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
