import React from 'react';
import BoyCard from '../components/BoyCard';
import Container from '../components/Container';
import bonesPhoto from '../images/bones.png';
import brianPhoto from '../images/brian.png';
import cookiePhoto from '../images/cookie.png';
import dugPhoto from '../images/dug.png';
import johnPhoto from '../images/john.png';
import pistolPhoto from '../images/pistol.png';
import princePhoto from '../images/prince.png';
import willPhoto from '../images/will.png';

const BOYS = [
  {
    name: 'Pistol',
    img: pistolPhoto,
  },
  {
    name: 'Bones',
    img: bonesPhoto,
  },
  {
    name: 'The Snakehead',
    img: johnPhoto,
  },
  {
    name: 'Cookie',
    img: cookiePhoto,
  },
  {
    name: 'The Professor',
    img: willPhoto,
  },
  {
    name: 'Fat Prince',
    img: princePhoto,
  },
  {
    name: 'Spanky',
    img: brianPhoto,
  },
  {
    name: 'Dug',
    img: dugPhoto,
  },
];

export default function BoysPage() {
  return (
    <Container>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 pt-8 pb-16">
        {BOYS.map((boy, index) => <BoyCard key={index} name={boy.name} img={boy.img} />)}
      </div>
    </Container>
  );
};
