import React from 'react';
import Nav from './Nav';

export default function Container({ children }) {
  return (
    <>
      <title>Bad Boys Book Bangers</title>
      <Nav />
      <main className="mx-6 md:mx-28 md:px-8 bg-white shadow-2xl">
        {children}
      </main>
    </>
  );
}
