import React from "react";

export default function BoyCard({ img, name }) {
  return (
    <div className="flex flex-row w-full md:w-96 h-40 rounded shadow-md cursor-pointer hover:shadow-lg">
      <img className="h-40 object-cover" src={img} alt="Portrait" />
      <div className="w-full h-full flex justify-center items-center">
        <div className="font-header text-2xl text-gray-700 tracking-wider">
          {name}
        </div>
      </div>
    </div>
  );
}
